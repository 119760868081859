<template>
  <div class="mds-rich-text">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { messages } from "./static";
import authorization  from "../mixins/authorization";

export default {
  mixins: [authorization],
  data() {
    return {
      seconds: 1,
      message: "",
    };
  },
  mounted() {
    //set a timeout so we can give AL.js some more time before accessing localstorage, because if we don't do this ALJS accesses it too early, sometimes retrieving the previous/null value from localstorage
    setTimeout(() => {
      if (this.isTokenInStorage && this.isExpiryDateInStorage) {
        this.message = messages.success;
        this.redirectToLogin();
      } else {
        this.message = messages.error;
      }
    }, this.seconds * 1000);
  },
};
</script>
