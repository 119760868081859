import { environmentsMap } from "../static/environmentsMap";

export default {
  data() {
    return {
      originalUrl: decodeURIComponent(this.$route.query[this.$environment.config("ORIGINAL_URL_PARAM_NAME")] || ""),
    };
  },
  methods: {
    saveOriginalUrl() {
      if (!this.originalUrl || localStorage.getItem("originalUrl")) {
        return;
      }

      localStorage.setItem("originalUrl", this.originalUrl);
    },
    redirectToOrginalUrlOr(defaultRedirectFunction) {
      const storedOriginalUrl = localStorage.getItem("originalUrl");

      if (!storedOriginalUrl) {
        return defaultRedirectFunction();
      }

      localStorage.removeItem("originalUrl");

      const allowedHosts = Object.keys(environmentsMap);
      const host = new URL(storedOriginalUrl).host;
      if (allowedHosts.includes(host)) {
        window.location.replace(storedOriginalUrl);
        window.location.reload();
      }
    },
  },
};
