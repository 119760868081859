<template>
  <div class="mds-rich-text">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { messages } from "./static";
import authorization from "../mixins/authorization";

export default {
  mixins: [authorization],
  data() {
    return {
      message: messages.logout,
    };
  },
  mounted() {
    this.removeCookies();
    this.clearLocalStorage();
    this.redirectToLogout();
  },
};
</script>
