import Vue from "vue";
import VueResource from "vue-resource";
import App from "./App.vue";
import router from "./router";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { UserAgentPublicApp } from "@maersk/iam-al";
import Environments from "./environments";
import ALJSHelper from "./helpers/aljs-helper";

const environment = new Environments();

if (process.env.NODE_ENV === "production") {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
} else if (process.env.NODE_ENV === "development") {
  // These parameters are only used in localhost
  // Local vue config
  Vue.config.devtools = true;
  Vue.config.performance = true;
}

Vue.config.productionTip = false;

Vue.use(VueCryptojs);
Vue.use(VueResource);
Vue.use(VueCookies, { secure: true, sameSite: "Strict" });

const aljs_config = ALJSHelper.createConfig({
  redirect_uri: `${environment.config("BASE_URI")}/${environment.config("REDIRECT_PATH")}`,
  client_id: environment.config("CLIENT_ID"),
  authority: environment.config("AUTHORITY"),
});

Vue.prototype.$aljs_config = aljs_config;
Vue.prototype.$al = new UserAgentPublicApp(aljs_config);

//This is where your environment variables get passed to.
Vue.prototype.$environment = environment;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#maersk-app");
