import { names } from "@/router/static";

export default {
  methods: {
    login: function () {
      this.$al.loginRedirect({
        scopes: ["openid", "profile", "email"],
        entity_type: this.$environment.config("ENTITY_TYPE"),
        entity_code: this.$environment.config("ENTITY_CODE"),
      });
    },
    removeCookies: function () {
      this.$cookies.remove(this.$environment.config("COOKIE_ID_TOKEN_STORE_NAME"));
      this.$cookies.remove(this.$environment.config("COOKIE_ACCESS_TOKEN_STORE_NAME"));
      this.$cookies.remove(this.$environment.config("COOKIE_REFRESH_TOKEN_STORE_NAME"));
      this.$cookies.remove(this.$environment.config("COOKIE_EXPIRY_DATE_STORE_NAME"));
    },
    setCookies: function ({ id_token, access_token, refresh_token, expiry_date }) {
      this.$cookies.set(this.$environment.config("COOKIE_ID_TOKEN_STORE_NAME"), id_token);
      this.$cookies.set(this.$environment.config("COOKIE_ACCESS_TOKEN_STORE_NAME"), access_token, new Date(Number(expiry_date)));
      this.$cookies.set(this.$environment.config("COOKIE_REFRESH_TOKEN_STORE_NAME"), refresh_token);
      this.$cookies.set(this.$environment.config("COOKIE_EXPIRY_DATE_STORE_NAME"), expiry_date);
    },
    redirectToTargetApplicaiton: function () {
      window.location.href = this.$environment.config("BASE_URI");
    },
    redirectToLogout: function () {
      window.location.href = `${this.$environment.config("LOGOUT_URI")}?redirect_uri=${this.$environment.config("BASE_URI")}`;
    },
    redirectToLogin: function () {
      this.$router.push({ name: names.login });
    },
    clearLocalStorage: function() {
      localStorage.clear();
    }
  },
  computed: {
    isTokenInStorage() {
      return this.$al.storage.access_token;
    },
    isExpiryDateInStorage() {
      return this.$al.storage.expiry_date;
    },
    isTokenExpired() {
      return !this.$al.storage.expiry_date || Date.now() >= Number(this.$al.storage.expiry_date);
    },
  },
};
