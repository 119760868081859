import { environmentNames } from "./static/environmentNames";
import { environmentsMap } from "./static/environmentsMap";

export default class Environments {
  constructor() {
    const env = environmentsMap[window.location.host];

    const DEFAULT_VALUES = {
      AUTHORITY: "https://accounts-cdt.maersk.com/thirdparty-cdt",
      CLIENT_ID: "thirdparty",
      LOGOUT_URI: "https://accounts-cdt.maersk.com/thirdparty-cdt/auth/logout",
      REDIRECT_PATH: "login-callback",
      BASE_URI: window.location.origin,
      ORIGINAL_URL_PARAM_NAME: "matsonlineOriginalURI",
    };

    let OVERRIDES = {};
    switch (env) {
      case environmentNames.TEST:
        OVERRIDES = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.matsonlinecdt-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.matsonlinecdt-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.matsonlinecdt-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.matsonlinecdt-expiry-date",
        };
        break;
      case environmentNames.PREPROD:
        OVERRIDES = {
          AUTHORITY: "https://accounts-stage.maersk.com/thirdparty-stage",
          LOGOUT_URI: "https://accounts-stage.maersk.com/thirdparty-stage/auth/logout",
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-stage.matsonlinestage-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-stage.matsonlinestage-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-stage.matsonlinestage-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-stage.matsonlinestage-expiry-date",
        };
        break;
      case environmentNames.PROD:
        OVERRIDES = {
          AUTHORITY: "https://accounts.maersk.com/thirdparty",
          LOGOUT_URI: "https://accounts.maersk.com/thirdparty/auth/logout",
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty.matsonline-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty.matsonline-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty.matsonline-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty.matsonline-expiry-date",
        };
        break;
      default:
        throw new Error(`Unknown Environment ${env}`);
    }
    this.configs = Object.assign(DEFAULT_VALUES, OVERRIDES);
  }
  config(config_name) {
    return this.configs[config_name];
  }
}
